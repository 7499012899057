import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import {
  SidebarProviders,
  MobileArrowBottom,
  MobileArrowTop,
  MobileArrowLeft,
  MobileArrowRight,
} from '@agendapro/emerald-icons';
import { AlertModal, AvatarEmployee, devices, Paragraph } from '@/UI';
import { useIframeRouter } from '@/hooks/useIframeRouter';
import { useShowComponent } from '@/hooks/showComponent';

import * as St from './SliderAvatarEmployees.styles';
import { useServiceProviders } from '@/services/serviceProviders';
import SliderAvatarEmployeesSkeleton from '../SliderAvatarEmployeesSkeleton';
import { AvatarImg, AvatarWrapper, CustomInitialsAvatar } from '@/UI/Molecules/AvatarEmployee/AvatarEmployee.styles';

export const SliderAvatarEmployees: FC<{ hasSelectedServices: boolean }> = ({ hasSelectedServices }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { formatUrl, routerPush } = useIframeRouter();
  const {
    countryCode,
    locationId,
    providerId,
    token,
    creative_source: creativeSource,
  } = router.query as {
    countryCode: string;
    locationId: string;
    providerId: string;
    token: string | null;
    // eslint-disable-next-line camelcase
    creative_source?: string;
  };
  const { data: providersByLocation, isLoading } = useServiceProviders(locationId);
  const [selectedId, setSelectedId] = useState<number>();
  const [showProfessionals, setShowProfessionals] = useState(false);
  const avatarScrollDiv = useRef<HTMLDivElement>(null);

  const { show, hide, isShown } = useShowComponent();
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  let queryParam = '';

  if (token && creativeSource) {
    queryParam = `?token=${token}&creative_source=${creativeSource}`;
  } else if (token) {
    queryParam = `?token=${token}`;
  } else if (creativeSource) {
    queryParam = `?creative_source=${creativeSource}`;
  }

  const handleRouting = (id) => {
    if (hasSelectedServices) {
      setSelectedId(id);
      return show();
    }
    return routerPush(`/${countryCode}/sucursal/${locationId}/profesional/${id}${queryParam}`);
  };

  const professionalsList = providersByLocation
    ?.filter((p) => p.onlineBooking && p.services.length)
    .sort((a, b) => a.order - b.order);

  const totalPages = professionalsList && Math.ceil(professionalsList.length / 5);
  const [isShownPage, setIsShownPage] = useState(0);

  const ListAvatarEmployees = (list) =>
    list?.map((p) => {
      if (hasSelectedServices) {
        return (
          <St.ProviderButton
            key={p.id}
            data-testid="providerLink"
            onClick={() => handleRouting(p.id)}
            data-cy="provider_button"
          >
            <AvatarEmployee name={p.publicName} image={p.image} />
          </St.ProviderButton>
        );
      }
      return (
        <Link
          key={p.id}
          href={formatUrl(`/${countryCode}/sucursal/${locationId}/profesional/${p.id}${queryParam}`)}
          passHref
        >
          <St.NavLink data-testid="providerLink" data-cy="provider_link">
            <AvatarEmployee name={p.publicName} image={p.image} />
          </St.NavLink>
        </Link>
      );
    });

  if (providerId) {
    return null;
  }

  if (isLoading) {
    return <SliderAvatarEmployeesSkeleton />;
  }

  return (
    <St.Wrapper>
      {!!providersByLocation && (
        <>
          {!isXLarge && (
            <button
              className="flex flex-row gap-2 items-center w-full border-0 border-t border-solid border-border-primary px-1 pt-2 mt-2 duration-500 ease-in h-[40px] max-h-[40px] [&_svg]:[-webkit-transform:translate(0px,0px)]"
              type="button"
              tabIndex={0}
              onClick={() => setShowProfessionals((prev) => !prev)}
              onKeyDown={() => setShowProfessionals((prev) => !prev)}
            >
              <SidebarProviders size={18} color={palette.textLink} />
              <Paragraph>{t('SLIDER_AVATAR_EMPLOYEES.TITLE')}</Paragraph>
              {!showProfessionals && (
                <div className="flex items-center w-[118px] ml-3">
                  {professionalsList?.slice(0, 5).map((user) => (
                    <div key={user.id}>
                      {user?.image ? (
                        <AvatarWrapper small className="-ml-3">
                          <AvatarImg
                            src={user?.image}
                            alt={user.publicName}
                            width={26}
                            height={26}
                            quality={100}
                            data-testid="avatarImage"
                          />
                        </AvatarWrapper>
                      ) : (
                        <AvatarWrapper small className="-ml-3">
                          <CustomInitialsAvatar name={user.publicName} small />
                        </AvatarWrapper>
                      )}
                    </div>
                  ))}
                  {professionalsList && professionalsList.length > 5 && (
                    <div className="-ml-3 h-[28px] w-[28px] rounded-full bg-background-primaryInverse flex justify-center items-center z-[1]">
                      <p className="text-xs text-white font-semibold text-center">+{professionalsList.length - 5}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="p-1 bg-background-tertiary rounded-full ml-auto" data-testid="mobile-show-professionals">
                {showProfessionals ? <MobileArrowTop size={16} /> : <MobileArrowBottom size={16} />}
              </div>
            </button>
          )}
          {isXLarge && <p className="text-[#1D1D3D] text-sm font-bold mb-2">{t('SLIDER_AVATAR_EMPLOYEES.TITLE')}</p>}
          {isXLarge ? (
            <div data-testid="sliderContainer" className="flex flex-col items-center">
              <div className="flex flex-row items-center w-full">
                {isShownPage > 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      setIsShownPage((prev) => prev - 1);

                      if (avatarScrollDiv) {
                        const scrollWidth = 340;

                        avatarScrollDiv.current?.scrollTo({
                          top: 0,
                          left: (avatarScrollDiv.current?.scrollLeft || 0) - scrollWidth,
                          behavior: 'smooth',
                        });
                      }
                    }}
                    data-testid="prev-professionals"
                  >
                    <MobileArrowLeft size={16} />
                  </button>
                ) : (
                  <div />
                )}
                <div
                  className="flex flex-row items-start gap-[2px] justify-between max-w-[340px] overflow-hidden"
                  ref={avatarScrollDiv}
                  id="scroll-avatars-container"
                >
                  {ListAvatarEmployees(professionalsList)}
                </div>
                {totalPages && isShownPage + 1 < totalPages ? (
                  <button
                    type="button"
                    onClick={() => {
                      const lastPage = isShownPage + 1 === totalPages;

                      setIsShownPage((prev) => prev + 1);

                      if (avatarScrollDiv) {
                        const scrollWidth = !lastPage ? 340 : professionalsList.length % 5;

                        avatarScrollDiv.current?.scrollTo({
                          top: 0,
                          left: (avatarScrollDiv.current?.scrollLeft || 0) + scrollWidth,
                          behavior: 'smooth',
                        });
                      }
                    }}
                    data-testid="next-professionals"
                  >
                    <MobileArrowRight size={16} />
                  </button>
                ) : (
                  <div />
                )}
              </div>
              {totalPages && totalPages > 1 && (
                <div className="flex flex-row items-center justify-center gap-1 mt-2">
                  {[...Array(totalPages).keys()].map((el) => (
                    <div
                      className={`rounded h-2 w-2 ${
                        isShownPage === el ? 'bg-background-primaryInverse' : 'bg-background-avatar'
                      }`}
                      key={el}
                    />
                  ))}
                </div>
              )}
            </div>
          ) : (
            <St.SliderAvatarMobile
              className={`transition-[height] duration-500 ${showProfessionals ? 'h-[98px] pt-3' : 'h-0'}`}
            >
              {showProfessionals && ListAvatarEmployees(professionalsList)}
            </St.SliderAvatarMobile>
          )}
        </>
      )}
      {isShown && (
        <AlertModal
          className="alert"
          confirmButtonText={t('SLIDER_AVATAR_EMPLOYEES.ALERT_BUTTON')}
          title={t('SLIDER_AVATAR_EMPLOYEES.ALERT_TITLE')}
          description={t('SLIDER_AVATAR_EMPLOYEES.ALERT_DESCRIPTION')}
          showCancelButton
          onCancel={hide}
          onConfirm={() =>
            routerPush(
              `/${countryCode}/sucursal/${locationId}/profesional/${selectedId}/${
                creativeSource ? `?creative_source=${creativeSource}` : ''
              }`,
            )
          }
        />
      )}
    </St.Wrapper>
  );
};
